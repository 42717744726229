import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.videos,"server-items-length":_vm.total,"options":_vm.options,"item-key":"id","show-select":"","footer-props":{ itemsPerPageOptions: [10, 20, 50] }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,[_vm._v("Videos In Processing")]),_c(VSpacer),(_vm.showRetrySelected)?_c(VBtn,{attrs:{"outlined":"","small":"","color":"default"},on:{"click":_vm.retrySelected}},[_vm._v(" Retry Selected ")]):_vm._e(),_c(VBtn,{staticClass:"mx-2",attrs:{"outlined":"","small":"","color":"default"},on:{"click":_vm.retryAll}},[_vm._v(" Retry All ")]),(_vm.selected.length)?_c(VBtn,{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.showDeleteDialog = true}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c(VRow,{staticClass:"py-5",attrs:{"align":"center"}},[_c('div',{staticClass:"thumbnail"},[_c(VImg,{staticClass:"mr-2",attrs:{"src":item.thumb_url || item.yt_thumb_url,"height":50,"aspect-ratio":16 / 9,"contain":""}})],1),_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(item.title))])])]}},{key:"item.channels",fn:function(ref){
var item = ref.item;
return _vm._l((item.channels),function(ref){
var id = ref.id;
var name = ref.name;
return _c('div',{key:id},[_vm._v(" "+_vm._s(name)+" ")])})}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.updated_at).format("DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.error",fn:function(ref){
var item = ref.item;
return [(item.error)?_c('span',{staticClass:"red--text"},[_vm._v("Please click the 'Retry' button")]):_c('span',[_vm._v("--")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.showDeleteDialog)?_c(VDialog,{attrs:{"value":true,"max-width":"290"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("are you sure"))+" ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.showDeleteDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.confirmedDelete}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }